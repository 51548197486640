
import React from 'react';
import { Shield, Users, Award, Clock, MapPin, Star } from 'lucide-react';

const AboutUs: React.FC = () => {
  const stats = [
    { value: '15+', label: 'Years Experience' },
    { value: '1000+', label: 'Happy Clients' },
    { value: '24/7', label: 'Support' },
    { value: '50+', label: 'IT Experts' }
  ];

  const values = [
    {
      icon: Shield,
      title: 'Trust & Reliability',
      description: 'Building long-term relationships through consistent, quality service delivery.'
    },
    {
      icon: Users,
      title: 'Customer Focus',
      description: 'Understanding and exceeding our clients unique technology needs.'
    },
    {
      icon: Award,
      title: 'Excellence',
      description: 'Delivering superior IT solutions with exceptional attention to detail.'
    },
    {
      icon: Clock,
      title: 'Responsiveness',
      description: '24/7 support with quick response times for all IT emergencies.'
    }
  ];

  const team = [
    {
      name: 'John Smith',
      role: 'Founder & CEO',
      image: '/api/placeholder/300/300',
      description: '15+ years in IT solutions and enterprise systems.'
    },
    {
      name: 'Sarah Johnson',
      role: 'Technical Director',
      image: '/api/placeholder/300/300',
      description: 'Expert in cybersecurity and cloud infrastructure.'
    },
    {
      name: 'Michael Chen',
      role: 'Service Manager',
      image: '/api/placeholder/300/300',
      description: 'Specialized in customer service and IT operations.'
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800 text-white py-24">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute w-96 h-96 -top-48 -right-48 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
          <div className="absolute w-96 h-96 -bottom-48 -left-48 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        </div>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Adelaide's Trusted IT Partner
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              We're more than just an IT company. We're your technology partner, committed to delivering innovative solutions that drive your success.
            </p>
            <div className="flex flex-wrap gap-4">
              {stats.map((stat, index) => (
                <div key={index} className="bg-white/10 backdrop-blur-sm rounded-lg p-6 flex-1">
                  <div className="text-3xl font-bold">{stat.value}</div>
                  <div className="text-blue-100">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Our Story</h2>
              <p className="text-gray-600 mb-6">
                Founded in 2009, 2Mtechelp has grown from a small IT support team to one of Adelaide's leading technology solution providers. Our journey has been driven by a singular mission: to make enterprise-grade IT solutions accessible to businesses of all sizes.
              </p>
              <p className="text-gray-600 mb-6">
                Over the years, we've helped hundreds of businesses across Adelaide and the Hills region transform their IT infrastructure, enhance their security, and embrace modern cloud solutions.
              </p>
              <div className="bg-blue-50 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                  <h3 className="font-semibold">Local Expertise</h3>
                </div>
                <p className="text-gray-600">
                  As a local Adelaide business, we understand the unique challenges and opportunities in our community. Our team lives and works here, providing personalized service with a deep understanding of local business needs.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <img src="/api/placeholder/300/400" alt="Office" className="rounded-lg w-full h-full object-cover" />
              <img src="/api/placeholder/300/400" alt="Team" className="rounded-lg w-full h-full object-cover mt-8" />
            </div>
          </div>
        </div>
      </div>

      {/* Our Values Section */}
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Our Values</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              These core values guide everything we do, from how we treat our clients to how we develop our solutions.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div key={index} className="bg-white rounded-xl p-6 shadow-sm hover:shadow-lg transition-shadow">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                  <value.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-bold mb-2">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Our Team Section */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Meet Our Team</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our experts bring together years of experience in IT services and solutions.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <div key={index} className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-lg transition-shadow">
                <img 
                  src={member.image} 
                  alt={member.name} 
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-1">{member.name}</h3>
                  <p className="text-blue-600 font-medium mb-3">{member.role}</p>
                  <p className="text-gray-600">{member.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Join hundreds of satisfied clients who trust us with their IT needs. Contact us today for a free consultation.
          </p>
          <div className="flex justify-center space-x-4">
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Book Consultation
            </button>
            <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
