import React from 'react';
import { FileText } from 'lucide-react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* Header */}
          <div className="flex items-center space-x-4 mb-8">
            <FileText className="h-8 w-8 text-blue-600" />
            <h1 className="text-3xl font-bold text-gray-900">Terms of Service</h1>
          </div>
          <div className="text-gray-600 space-y-6">
            <p className="text-sm">Last updated: {new Date().toLocaleDateString()}</p>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">1. Acceptance of Terms</h2>
              <p>
                By accessing and using 2Mtechelp's services, you agree to be bound by these Terms of Service. 
                If you disagree with any part of these terms, you may not access our services.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">2. Service Description</h2>
              <p>2Mtechelp provides IT services including:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>IT Support and Maintenance</li>
                <li>Cloud Solutions</li>
                <li>Cybersecurity Services</li>
                <li>Software Development</li>
                <li>Hardware Solutions</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">3. User Obligations</h2>
              <p>As a user of our services, you agree to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Use services only for authorized purposes</li>
                <li>Report any security vulnerabilities or misuse</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">4. Payment Terms</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>Payment is required before service delivery</li>
                <li>All prices are in Australian Dollars (AUD)</li>
                <li>We accept major credit cards and bank transfers</li>
                <li>Refunds are subject to our refund policy</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">5. Limitation of Liability</h2>
              <p>
                2Mtechelp shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">6. Contact Information</h2>
              <p>For any questions regarding these Terms of Service, please contact us at:</p>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>Email: legal@2mtechelp.com</p>
                <p>Phone: +1 (555) 123-4567</p>
                <p>Address: Adelaide, South Australia</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;