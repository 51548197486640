import React from 'react';
import { Sparkles, MessageSquare, Wrench, CheckCircle, LucideIcon } from 'lucide-react';

interface ProcessStepProps {
 icon: LucideIcon;
 step: number;
 title: string; 
 description: string;
}

const ProcessStep: React.FC<ProcessStepProps> = ({ icon: Icon, step, title, description }) => (
 <div className="relative">
   <div className="flex items-center mb-4">
     <div className="w-16 h-16 bg-blue-100 rounded-xl flex items-center justify-center">
       <Icon className="h-8 w-8 text-blue-600" />
     </div>
   </div>
   <div className="mb-8">
     <div className="text-sm text-blue-600 font-semibold mb-2">Step {step}</div>
     <h3 className="text-xl font-bold mb-2">{title}</h3>
     <p className="text-gray-600">{description}</p>
   </div>
 </div>
);

const ProcessSection: React.FC = () => {
 const steps = [
   {
     icon: MessageSquare,
     title: "Initial Consultation",
     description: "We discuss your needs and challenges to understand your business goals"
   },
   {
     icon: Sparkles,
     title: "Solution Design", 
     description: "Our experts design a tailored solution to meet your specific requirements"
   },
   {
     icon: Wrench,
     title: "Implementation",
     description: "We deploy your solution with minimal disruption to your operations"
   },
   {
     icon: CheckCircle,
     title: "Ongoing Support",
     description: "Continuous monitoring and support to ensure optimal performance"
   }
 ];

 return (
   <section className="py-24 bg-gray-50">
     <div className="container mx-auto px-4">
       <div className="text-center mb-16">
         <h2 className="text-4xl font-bold mb-6">How We Work</h2>
         <p className="text-xl text-gray-600 max-w-2xl mx-auto">
           Our proven process ensures successful implementation and long-term results
         </p>
       </div>

       <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
         {steps.map((step, index) => (
           <ProcessStep 
             key={index}
             step={index + 1}
             {...step}
           />
         ))}
       </div>

       <div className="mt-16 flex justify-center">
         <button className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
           Start Your Project
         </button>
       </div>
     </div>
   </section>
 );
};

export default ProcessSection;