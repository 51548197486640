import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ArrowRight, Zap, Clock, DollarSign, MapPin, Shield, Users } from 'lucide-react';

interface PerformanceData {
  month: string;
  efficiency: number;
  cost: number;
}

const SolutionsShowcase: React.FC = () => {
  const performanceData: PerformanceData[] = [
    { month: 'Jan', efficiency: 92, cost: 85 },
    { month: 'Feb', efficiency: 94, cost: 82 },
    { month: 'Mar', efficiency: 96, cost: 80 },
    { month: 'Apr', efficiency: 95, cost: 78 },
    { month: 'May', efficiency: 98, cost: 75 },
    { month: 'Jun', efficiency: 99, cost: 72 }
  ];

  const localBenefits = [
    {
      icon: MapPin,
      title: "Local Adelaide Team",
      description: "Deep understanding of Adelaide & Hills business needs"
    },
    {
      icon: Shield,
      title: "Trusted Expertise",
      description: "Reliable & secure solutions with proven track record"
    },
    {
      icon: Users,
      title: "Personalized Support",
      description: "Fast, responsive local support whenever you need it"
    }
  ];

  return (
    <section className="py-24 bg-white overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center mb-16">
          <div>
            <div className="inline-flex items-center bg-blue-100 rounded-full px-4 py-2 mb-4">
              <Zap className="w-4 h-4 text-blue-600 mr-2" />
              <span className="text-blue-600 font-medium">Adelaide's IT Partner</span>
            </div>
            <h2 className="text-4xl font-bold mb-6 leading-tight">
              Local Expertise, Enterprise-Grade Solutions
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Trusted by businesses across Adelaide and Hills regions for reliable, affordable, and secure IT solutions.
            </p>
            
            <div className="grid grid-cols-2 gap-8 mb-8">
              {[
                { icon: Clock, value: '24/7', label: 'Local Support' },
                { icon: DollarSign, value: '30%', label: 'Cost Savings' }
              ].map((stat, index) => (
                <div key={index} className="flex items-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mr-4">
                    <stat.icon className="w-6 h-6 text-blue-600" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-blue-600">{stat.value}</div>
                    <div className="text-gray-600">{stat.label}</div>
                  </div>
                </div>
              ))}
            </div>

            <button className="group inline-flex items-center text-blue-600 font-semibold hover:text-blue-800">
              View Local Success Stories
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6 lg:ml-auto w-full max-w-xl">
            <h3 className="text-xl font-bold mb-4">Client Success Metrics</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={performanceData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
                    }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="efficiency" 
                    stroke="#2563eb" 
                    strokeWidth={3}
                    name="Efficiency"
                    dot={{ strokeWidth: 2, r: 4 }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="cost" 
                    stroke="#16a34a" 
                    strokeWidth={3}
                    name="Cost"
                    dot={{ strokeWidth: 2, r: 4 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {localBenefits.map((benefit, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-8 hover:shadow-lg transition-shadow">
              <div className="flex items-center mb-4">
                <benefit.icon className="w-6 h-6 text-blue-600 mr-3" />
                <h3 className="text-xl font-bold">{benefit.title}</h3>
              </div>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolutionsShowcase;