import React, { useState, useEffect } from 'react';
import { 
  Menu, 
  X, 
  ChevronDown, 
  Server, 
  MapPin, 
  Clock, 
  Shield,
  Laptop,
  Phone,
  HelpCircle
} from 'lucide-react';

interface MenuItem {
  label: string;
  children?: {
    title: string;
    description: string;
  }[];
}

interface ServiceArea {
  region: string;
  areas: string[];
  responseTime: string;
}

const BannerSection: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [showLocationInfo, setShowLocationInfo] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems: MenuItem[] = [
    {
      label: 'IT Services',
      children: [
        { title: 'Business IT Support', description: 'Comprehensive IT solutions for businesses' },
        { title: 'Cloud Services', description: 'Secure cloud infrastructure and migration' },
        { title: 'Cybersecurity', description: 'Advanced security solutions' },
        { title: 'Network Solutions', description: 'Professional networking services' }
      ]
    },
    {
      label: 'Home Services',
      children: [
        { title: 'Home IT Support', description: 'Expert support for home technology' },
        { title: 'Smart Home Setup', description: 'Complete smart home installations' },
        { title: 'Wi-Fi Solutions', description: 'Optimized home networking' },
        { title: 'Device Support', description: 'Computer and device repairs' }
      ]
    },
    { label: 'Service Areas' },
    { label: 'About' },
    { label: 'Contact' }
  ];

  const serviceAreas: ServiceArea[] = [
    {
      region: 'Adelaide City',
      areas: ['CBD', 'North Adelaide', 'Unley', 'Norwood'],
      responseTime: '< 1 hour'
    },
    {
      region: 'Hills Region',
      areas: ['Mount Barker', 'Stirling', 'Hahndorf'],
      responseTime: '< 2 hours'
    },
    {
      region: 'Extended Areas',
      areas: ['Murray Bridge', 'Surrounding Suburbs'],
      responseTime: 'Same Day'
    }
  ];

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300`}>
      {/* Service Area Top Bar */}
      <div className="bg-blue-600 text-white py-1">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center text-sm">
            <div className="flex items-center">
              <div 
                className="flex items-center cursor-pointer"
                onClick={() => setShowLocationInfo(!showLocationInfo)}
              >
                <MapPin className="w-4 h-4 mr-1" />
                <span className="hidden sm:inline">Door-Step IT Support in</span>
                <span className="flex items-center ml-2 font-semibold">
                  Adelaide & Hills
                  <ChevronDown className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                    showLocationInfo ? 'rotate-180' : ''
                  }`} />
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                <span>24/7 Support</span>
              </div>
              <div className="hidden sm:flex items-center">
                <Phone className="w-4 h-4 mr-1" />
                <span>08 1234 5678</span>
              </div>
            </div>
          </div>
        </div>

        {/* Service Areas Dropdown */}
        {showLocationInfo && (
          <div className="absolute top-full left-0 w-full bg-white shadow-lg rounded-b-lg py-4 animate-fadeIn">
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {serviceAreas.map((area, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-semibold text-gray-900 mb-3 flex items-center">
                      <MapPin className="w-4 h-4 text-blue-600 mr-2" />
                      {area.region}
                    </h3>
                    <div className="space-y-2">
                      <div className="text-sm text-gray-600">
                        {area.areas.map((location, idx) => (
                          <div key={idx} className="flex items-center py-1">
                            <span className="w-1.5 h-1.5 bg-blue-600 rounded-full mr-2"></span>
                            {location}
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center text-sm text-blue-600 font-medium mt-2">
                        <Clock className="w-3 h-3 mr-1" />
                        Response Time: {area.responseTime}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Main Navigation */}
      <div className={`${isScrolled ? 'bg-white shadow-lg' : 'bg-white/90 backdrop-blur-sm'} transition-all duration-300`}>
        <div className="container mx-auto px-4">
          {/* Rest of your navigation code... */}
        </div>
      </div>
    </nav>
  );
};

export default BannerSection;