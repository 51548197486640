import React, { useState } from 'react';
import { Shield, Cloud, Code, Server, ArrowRight, CheckCircle, ChevronRight, LucideIcon } from 'lucide-react';

interface ServiceCardProps {
  title: string;
  description: string;
  features: string[];
  icon: LucideIcon;
  isActive: boolean;
  onClick: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ 
  title, 
  description, 
  features, 
  icon: Icon, 
  isActive, 
  onClick 
}) => (
  <div 
    className={`cursor-pointer transition-all duration-300 h-full rounded-lg ${
      isActive 
        ? 'bg-blue-600 transform scale-105 shadow-xl' 
        : 'bg-white hover:shadow-lg hover:translate-y-[-4px]'
    }`}
    onClick={onClick}
  >
    <div className="p-8">
      <div className={`w-14 h-14 rounded-xl flex items-center justify-center mb-6 ${
        isActive ? 'bg-white' : 'bg-blue-100'
      }`}>
        <Icon className="h-7 w-7 text-blue-600" />
      </div>
      
      <h3 className={`text-2xl font-bold mb-4 ${
        isActive ? 'text-white' : 'text-gray-900'
      }`}>
        {title}
      </h3>
      
      <p className={`mb-6 ${
        isActive ? 'text-white opacity-90' : 'text-gray-600'
      }`}>
        {description}
      </p>
      
      <ul className="space-y-3 mb-6">
        {features.map((feature: string, index: number) => (
          <li key={index} className="flex items-center">
            <CheckCircle className={`h-5 w-5 mr-3 ${
              isActive ? 'text-white' : 'text-green-500'
            }`} />
            <span className={`${
              isActive ? 'text-white opacity-90' : 'text-gray-700'
            }`}>
              {feature}
            </span>
          </li>
        ))}
      </ul>

      <button className={`group flex items-center font-semibold ${
        isActive ? 'text-white' : 'text-blue-600'
      }`}>
        Learn More 
        <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  </div>
);

interface Service {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[];
}

const ServicesShowcase: React.FC = () => {
  const [activeService, setActiveService] = useState<number>(0);

  const services: Service[] = [
    {
      icon: Cloud,
      title: "Cloud Solutions",
      description: "Enterprise-grade cloud infrastructure with seamless scalability",
      features: [
        "Multi-cloud Architecture",
        "Auto-scaling Solutions",
        "24/7 Monitoring",
        "Disaster Recovery"
      ]
    },
    {
      icon: Shield,
      title: "Cybersecurity",
      description: "Advanced security solutions to protect your digital assets",
      features: [
        "Threat Detection & Response",
        "Zero Trust Architecture",
        "Compliance Management",
        "Security Audits"
      ]
    },
    {
      icon: Code,
      title: "Custom Development",
      description: "Tailored software solutions for your unique needs",
      features: [
        "Enterprise Applications",
        "API Development",
        "Legacy Modernization",
        "Mobile Solutions"
      ]
    },
    {
      icon: Server,
      title: "IT Infrastructure",
      description: "Comprehensive IT solutions for modern businesses",
      features: [
        "Network Management",
        "Server Administration",
        "Hardware Solutions",
        "Tech Support"
      ]
    }
  ];

  return (
    <section className="py-24 bg-gray-50 relative overflow-hidden">
      <div className="absolute inset-0 opacity-5">
        <div className="absolute top-0 left-0 w-96 h-96 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl animate-blob"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000"></div>
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <div className="inline-flex items-center bg-blue-100 rounded-full px-4 py-2 mb-4">
            <Server className="w-4 h-4 text-blue-600 mr-2" />
            <span className="text-blue-600 font-medium">Our Services</span>
          </div>
          <h2 className="text-4xl font-bold mb-6 text-gray-900">
            Enterprise Solutions for Modern Businesses
          </h2>
          <p className="text-xl text-gray-600">
            Transform your business with our comprehensive suite of IT services
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              {...service}
              isActive={activeService === index}
              onClick={() => setActiveService(index)}
            />
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 p-8 bg-white rounded-2xl shadow-lg">
          {[
            { value: '99.9%', label: 'Uptime' },
            { value: '24/7', label: 'Support' },
            { value: '500+', label: 'Projects' },
            { value: '50+', label: 'Experts' }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-3xl font-bold text-blue-600 mb-2">{stat.value}</div>
              <div className="text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-20 text-center">
        <button className="inline-flex items-center bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors group">
          Explore All Services
          <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    </section>
  );
};

export default ServicesShowcase;