// CyberSecurityServices.tsx
import React from 'react';
import { FaShieldAlt, FaLock, FaSearch, FaServer, FaUserShield, FaCode } from 'react-icons/fa';

const CyberSecurityServices = () => {
  const services = [
    {
      icon: <FaShieldAlt className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Network Security",
      description: "Protect your network from unauthorized access and threats",
      features: [
        "Firewall Configuration",
        "Intrusion Detection",
        "VPN Setup",
        "24/7 Monitoring"
      ]
    },
    {
      icon: <FaLock className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Data Protection",
      description: "Secure your sensitive data with advanced encryption",
      features: [
        "Data Encryption",
        "Backup Solutions",
        "Access Control",
        "Data Recovery"
      ]
    },
    {
      icon: <FaSearch className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Security Assessment",
      description: "Comprehensive security audit and vulnerability testing",
      features: [
        "Vulnerability Scanning",
        "Risk Assessment",
        "Compliance Audit",
        "Security Reports"
      ]
    }
  ];

  const securityPackages = [
    {
      tier: "Basic Security",
      price: "$299/month",
      features: [
        "Antivirus Protection",
        "Firewall Setup",
        "Basic Monitoring",
        "Email Security"
      ]
    },
    {
      tier: "Business Pro",
      price: "$599/month",
      features: [
        "Advanced Threat Detection",
        "24/7 Monitoring",
        "Incident Response",
        "Employee Training"
      ]
    },
    {
      tier: "Enterprise",
      price: "Custom",
      features: [
        "Custom Security Solution",
        "Dedicated Security Team",
        "Advanced Analytics",
        "Compliance Management"
      ]
    }
  ];

  const securityProcess = [
    {
      icon: <FaSearch />,
      title: "Assessment",
      description: "Thorough security audit and vulnerability assessment"
    },
    {
      icon: <FaCode />,
      title: "Implementation",
      description: "Deploy custom security solutions and protocols"
    },
    {
      icon: <FaUserShield />,
      title: "Monitoring",
      description: "Continuous security monitoring and threat detection"
    },
    {
      icon: <FaServer />,
      title: "Maintenance",
      description: "Regular updates and security patch management"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Cyber Security Services</h1>
          <p className="text-xl text-gray-600">
            Protecting your digital assets with enterprise-grade security solutions
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
              {service.icon}
              <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600 mb-6">{service.description}</p>
              <ul className="space-y-3">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Security Process */}
        <div className="bg-white rounded-xl p-12 shadow-lg mb-16">
          <h2 className="text-3xl font-bold text-center mb-12">Our Security Process</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {securityProcess.map((step, index) => (
              <div key={index} className="text-center">
                <div className="text-blue-600 text-4xl mb-4 flex justify-center">
                  {step.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Security Packages */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {securityPackages.map((pkg, index) => (
            <div key={index} className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-bold mb-2">{pkg.tier}</h3>
              <p className="text-3xl text-blue-600 font-bold mb-6">{pkg.price}</p>
              <ul className="space-y-3">
                {pkg.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Emergency Response */}
        <div className="bg-gradient-to-r from-red-600 to-red-700 text-white rounded-xl p-12 text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">24/7 Security Incident Response</h2>
          <p className="mb-8 text-lg">Immediate response to security breaches and cyber attacks</p>
          <button className="bg-white text-red-600 px-8 py-3 rounded-lg font-semibold hover:bg-red-50 transition-colors">
            Emergency Response Line
          </button>
        </div>

        {/* Compliance Section */}
        <div className="bg-white rounded-xl p-12 shadow-lg text-center">
          <h2 className="text-3xl font-bold mb-8">Industry Compliance & Certifications</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {['ISO 27001', 'GDPR', 'HIPAA', 'PCI DSS'].map((cert, index) => (
              <div key={index} className="p-4 border-2 border-gray-200 rounded-lg">
                <p className="font-bold text-gray-700">{cert}</p>
                <p className="text-sm text-gray-500">Compliant</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CyberSecurityServices;