import React, { useState } from 'react';
import { Calendar, MapPin, Clock, User, Settings, CheckCircle, Phone, MessageSquare, AlertCircle, ChevronDown, Search } from 'lucide-react';

const TechnicianInterface: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('current');
  const [showJobDetails, setShowJobDetails] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      {/* Top Stats Bar */}
      <div className="bg-blue-600 text-white mb-6">
        <div className="max-w-7xl mx-auto p-4">
          <div className="grid grid-cols-4 gap-4">
            {[
              { label: 'Jobs Today', value: '8' },
              { label: 'Completed', value: '5' },
              { label: 'Average Time', value: '45m' },
              { label: 'Customer Rating', value: '4.9★' }
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-2xl font-bold">{stat.value}</div>
                <div className="text-sm opacity-80">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Header with Quick Actions */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                <Settings className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <h1 className="text-2xl font-bold">David Miller</h1>
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded text-sm">Available</span>
                </div>
                <div className="flex items-center space-x-4 text-sm text-gray-600">
                  <span>Senior Technician</span>
                  <span>|</span>
                  <span>ID: #2547</span>
                </div>
              </div>
            </div>
            <div className="flex space-x-3">
              <button className="p-2 bg-gray-100 rounded-lg hover:bg-gray-200">
                <MessageSquare className="w-5 h-5 text-gray-600" />
              </button>
              <button className="p-2 bg-gray-100 rounded-lg hover:bg-gray-200">
                <Phone className="w-5 h-5 text-gray-600" />
              </button>
              <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                Start Break
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content Area */}
          <div className="lg:col-span-2 space-y-6">
            {/* Job Navigation */}
            <div className="bg-white rounded-xl shadow-sm p-4">
              <div className="flex space-x-4 border-b">
                {['Current', 'Upcoming', 'Completed'].map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-2 ${
                      selectedTab === tab.toLowerCase()
                        ? 'border-b-2 border-blue-600 text-blue-600'
                        : 'text-gray-600'
                    }`}
                    onClick={() => setSelectedTab(tab.toLowerCase())}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>

            {/* Current Job Details */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex justify-between items-start mb-6">
                <div>
                  <div className="flex items-center space-x-2 mb-2">
                    <h2 className="text-xl font-bold">Network Setup and Configuration</h2>
                    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                      In Progress
                    </span>
                  </div>
                  <p className="text-gray-600">Job #JB-2547 • Started 45 minutes ago</p>
                </div>
                <button className="text-blue-600 hover:underline" onClick={() => setShowJobDetails(!showJobDetails)}>
                  {showJobDetails ? 'Hide Details' : 'Show Details'}
                </button>
              </div>

              {showJobDetails && (
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <User className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="font-medium">John Smith</p>
                        <p className="text-sm text-gray-600">Premium Customer</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <MapPin className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="font-medium">123 Adelaide Street</p>
                        <p className="text-sm text-gray-600">SA 5000</p>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <Clock className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="font-medium">Estimated Time</p>
                        <p className="text-sm text-gray-600">1 hour 30 minutes</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <AlertCircle className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="font-medium">Special Notes</p>
                        <p className="text-sm text-gray-600">Business critical setup</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Progress Tracking */}
              <div className="space-y-4">
                <h3 className="font-semibold">Service Progress</h3>
                <div className="space-y-3">
                  {[
                    { task: 'Initial Assessment', completed: true },
                    { task: 'Equipment Check', completed: true },
                    { task: 'Network Configuration', completed: false },
                    { task: 'Security Setup', completed: false },
                    { task: 'Client Training', completed: false }
                  ].map((task, index) => (
                    <div key={index} className="flex items-center space-x-3">
                      <div className={`w-5 h-5 rounded-full flex items-center justify-center ${
                        task.completed ? 'bg-green-100' : 'bg-gray-100'
                      }`}>
                        <CheckCircle className={`w-4 h-4 ${
                          task.completed ? 'text-green-600' : 'text-gray-400'
                        }`} />
                      </div>
                      <span className={task.completed ? 'text-gray-900' : 'text-gray-600'}>
                        {task.task}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-6 pt-6 border-t grid grid-cols-2 gap-4">
                <button className="py-2 px-4 border border-gray-200 rounded-lg hover:bg-gray-50">
                  Request Assistance
                </button>
                <button className="py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                  Complete Job
                </button>
              </div>
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="space-y-6">
            {/* Quick Search */}
            <div className="bg-white rounded-xl shadow-sm p-4">
              <div className="relative">
                <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                <input 
                  type="text"
                  placeholder="Search jobs or customers..."
                  className="w-full pl-10 pr-4 py-2 border rounded-lg"
                />
              </div>
            </div>

            {/* Upcoming Jobs */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Today's Schedule</h2>
              <div className="space-y-4">
                {[
                  { time: '2:30 PM', client: 'Sarah Johnson', service: 'Smart Home Setup', priority: 'High' },
                  { time: '4:00 PM', client: 'Mike Brown', service: 'IT Support', priority: 'Normal' },
                  { time: '5:30 PM', client: 'Lisa Chen', service: 'Network Setup', priority: 'Normal' }
                ].map((job, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer">
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 text-blue-600 mr-2" />
                        <p className="font-medium">{job.time}</p>
                      </div>
                      <span className={`text-sm px-2 py-1 rounded-full ${
                        job.priority === 'High' 
                          ? 'bg-red-100 text-red-800' 
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {job.priority}
                      </span>
                    </div>
                    <p className="font-medium">{job.client}</p>
                    <p className="text-sm text-gray-600">{job.service}</p>
                  </div>
                ))}
              </div>
              
              <button className="w-full mt-4 text-blue-600 hover:text-blue-800 text-sm">
                View Full Schedule →
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianInterface;