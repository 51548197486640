import React from 'react';
import { Cookie } from 'lucide-react';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* Header */}
          <div className="flex items-center space-x-4 mb-8">
            <Cookie className="h-8 w-8 text-blue-600" />
            <h1 className="text-3xl font-bold text-gray-900">Cookie Policy</h1>
          </div>
          <div className="text-gray-600 space-y-6">
            <p className="text-sm">Last updated: {new Date().toLocaleDateString()}</p>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">1. What Are Cookies</h2>
              <p>
                Cookies are small text files that are placed on your computer or mobile device when you visit our website. 
                They help us make the site work better for you and provide us with insights about how the site is used.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">2. Types of Cookies We Use</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium text-gray-800">Essential Cookies</h3>
                  <p>Required for basic site functionality and security. Cannot be disabled.</p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-800">Analytics Cookies</h3>
                  <p>Help us understand how visitors interact with our website.</p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-800">Functional Cookies</h3>
                  <p>Enable enhanced functionality and personalization.</p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-800">Marketing Cookies</h3>
                  <p>Used to track visitors across websites to display relevant advertisements.</p>
                </div>
              </div>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">3. How to Control Cookies</h2>
              <p>You can control cookies through your browser settings:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Chrome: Settings → Privacy and Security → Cookies</li>
                <li>Firefox: Options → Privacy & Security → Cookies</li>
                <li>Safari: Preferences → Privacy → Cookies</li>
                <li>Edge: Settings → Privacy & Security → Cookies</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">4. Cookie Preferences</h2>
              <p>
                You can manage your cookie preferences on our website using our cookie consent manager. 
                Click the "Cookie Settings" button in the footer to update your preferences at any time.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">5. Contact Us</h2>
              <p>If you have questions about our use of cookies, please contact us:</p>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>Email: privacy@2mtechelp.com</p>
                <p>Phone: +1 (555) 123-4567</p>
                <p>Address: Adelaide, South Australia</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;