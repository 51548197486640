
// components/SEO/Meta.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaProps {
  title: string;
  description: string;
  keywords: string;
  ogImage?: string;
}

const Meta: React.FC<MetaProps> = ({ 
  title, 
  description, 
  keywords,
  ogImage = '/images/og-default.jpg' 
}) => (
  <Helmet>
    <title>{title} | 2Mtechelp - IT Services Adelaide</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    
    {/* Open Graph */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:type" content="website" />
    
    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={ogImage} />
  </Helmet>
);

export default Meta;

// pages/Home.tsx example usage
const Home = () => (
  <>
    <Meta 
      title="Professional IT Services & Support"
      description="Adelaide's trusted IT service provider offering network solutions, smart home setup, and 24/7 tech support across Adelaide & Hills regions."
      keywords="IT services Adelaide, tech support, network setup, smart home installation, computer repair Adelaide"
    />
    {/* Rest of component */}
  </>
);




