import React from 'react';
import { Mail, Lock, User, ArrowLeft } from 'lucide-react';

const Login = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4">
    <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
      <div>
        <h2 className="text-3xl font-bold text-center">Welcome back</h2>
        <p className="text-gray-600 text-center mt-2">Sign in to your account</p>
      </div>
      <form className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="relative mt-1">
            <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="email"
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative mt-1">
            <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="password"
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input type="checkbox" className="h-4 w-4 text-blue-600" />
            <label className="ml-2 text-sm text-gray-600">Remember me</label>
          </div>
          <a href="#" className="text-sm text-blue-600 hover:text-blue-800">Forgot password?</a>
        </div>
        <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700">
          Sign in
        </button>
      </form>
      <div className="text-center">
        <span className="text-gray-600 text-sm">Don't have an account?</span>
        <a href="#" className="text-blue-600 hover:text-blue-800 text-sm font-medium ml-2">Sign up</a>
      </div>
    </div>
  </div>
);

const Register = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4">
    <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
      <div>
        <h2 className="text-3xl font-bold text-center">Create Account</h2>
        <p className="text-gray-600 text-center mt-2">Get started with our service</p>
      </div>
      <form className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              className="w-full px-4 py-2 border rounded-lg mt-1"
              placeholder="John"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              className="w-full px-4 py-2 border rounded-lg mt-1"
              placeholder="Doe"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="relative mt-1">
            <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="email"
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              placeholder="john@example.com"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative mt-1">
            <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="password"
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              placeholder="Create a password"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Phone</label>
          <input
            type="tel"
            className="w-full px-4 py-2 border rounded-lg mt-1"
            placeholder="+1 (555) 000-0000"
          />
        </div>
        <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700">
          Create Account
        </button>
      </form>
      <div className="text-center">
        <span className="text-gray-600 text-sm">Already have an account?</span>
        <a href="#" className="text-blue-600 hover:text-blue-800 text-sm font-medium ml-2">Sign in</a>
      </div>
    </div>
  </div>
);

const ForgotPassword = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4">
    <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
      <div>
        <h2 className="text-3xl font-bold text-center">Reset Password</h2>
        <p className="text-gray-600 text-center mt-2">Enter your email to reset your password</p>
      </div>
      <form className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="relative mt-1">
            <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="email"
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              placeholder="Enter your email"
            />
          </div>
        </div>
        <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700">
          Send Reset Link
        </button>
      </form>
      <div className="text-center">
        <a href="#" className="flex items-center justify-center text-blue-600 hover:text-blue-800 text-sm font-medium">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Sign in
        </a>
      </div>
    </div>
  </div>
);

const AuthScreens = { Login, Register, ForgotPassword };
export default AuthScreens;