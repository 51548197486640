// HeroSection.tsx
import React from 'react';
import { Wifi, Wrench, Home, Book, ArrowRight, Phone, Check } from 'lucide-react';

interface QuickAction {
  icon: any;
  title: string;
  description: string;
  link: string;
}

const HeroSection: React.FC = () => {
  const quickActions: QuickAction[] = [
    {
      icon: Phone,
      title: "IT Support",
      description: "24/7 remote & onsite support",
      link: "/support"
    },
    {
      icon: Home,
      title: "Home Services",
      description: "Smart home & networking",
      link: "/home-services"
    },
    {
      icon: Wrench,
      title: "Repairs",
      description: "Device repairs & upgrades",
      link: "/repairs"
    }
  ];

  return (
    <div className="relative pt-20 overflow-hidden">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-indigo-900">
        <div className="absolute inset-0 bg-black/30"></div>
        {/* Animated background shapes */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-48 -left-48 w-96 h-96 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
          <div className="absolute -bottom-48 -right-48 w-96 h-96 bg-indigo-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        </div>
      </div>

      <div className="relative container mx-auto px-4">
        <div className="pt-20 pb-24">
          {/* Main Content */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              {/* Badge */}
              <div className="inline-flex items-center bg-white/10 backdrop-blur-sm rounded-full px-4 py-2 mb-6">
                <div className="flex items-center">
                  <span className="w-2 h-2 bg-green-400 rounded-full mr-2"></span>
                  <span className="text-green-400 text-sm">24/7 Support Available</span>
                </div>
                <div className="w-px h-4 bg-white/20 mx-3"></div>
                <span className="text-white/90 text-sm">Adelaide's Trusted IT Partner</span>
              </div>

              {/* Heading */}
              <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
                Expert IT Solutions for Home & Business
              </h1>

              {/* Description */}
              <p className="text-xl text-blue-100 mb-8">
                From smart home setups to enterprise IT support, we provide comprehensive technology solutions across Adelaide and the Hills.
              </p>

              {/* Features List */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                {[
                  "24/7 Local Support",
                  "Smart Home Experts",
                  "Business IT Solutions",
                  "Fast Response Time"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center text-blue-100">
                    <Check className="w-5 h-5 mr-2 text-green-400" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>

              {/* CTA Buttons */}
              <div className="flex flex-wrap gap-4">
                <button className="bg-white text-blue-900 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition-all flex items-center">
                  Get Started
                  <ArrowRight className="ml-2 w-5 h-5" />
                </button>
                <button className="border-2 border-white text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                  View Services
                </button>
              </div>
            </div>

            {/* Quick Actions Cards */}
            <div className="grid grid-cols-1 gap-6">
              {quickActions.map((action, index) => (
                <div 
                  key={index}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-6 hover:bg-white/20 transition-all cursor-pointer border border-white/10"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-12 h-12 bg-white/10 rounded-lg flex items-center justify-center mr-4">
                        <action.icon className="w-6 h-6 text-white" />
                      </div>
                      <div>
                        <h3 className="text-white font-semibold text-lg">{action.title}</h3>
                        <p className="text-blue-100">{action.description}</p>
                      </div>
                    </div>
                    <ArrowRight className="w-5 h-5 text-blue-100" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Trust Indicators */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16 pt-16 border-t border-white/10">
            {[
              { value: "15+", label: "Years Experience" },
              { value: "1000+", label: "Happy Clients" },
              { value: "24/7", label: "Support" },
              { value: "<1hr", label: "Response Time" }
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
                <div className="text-blue-100 text-sm">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

// Add to your global CSS for animations
const styles = `
@keyframes blob {
  0% { transform: translate(0px, 0px) scale(1); }
  33% { transform: translate(30px, -50px) scale(1.1); }
  66% { transform: translate(-20px, 20px) scale(0.9); }
  100% { transform: translate(0px, 0px) scale(1); }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}
`;