// SoftwareDevelopmentService.tsx
import React from 'react';
import { FaCode, FaMobile, FaDatabase, FaCloud } from 'react-icons/fa';

const SoftwareDevelopmentService = () => {
  const services = [
    {
      icon: <FaCode className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Custom Software Development",
      description: "Tailored solutions for your unique business needs",
      features: [
        "Web Applications",
        "Desktop Software",
        "API Development"
      ]
    },
    {
      icon: <FaMobile className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Mobile App Development",
      description: "Native and cross-platform mobile applications",
      features: [
        "iOS Development",
        "Android Development",
        "React Native Apps"
      ]
    },
    {
      icon: <FaCloud className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Cloud Solutions",
      description: "Scalable cloud-based applications and services",
      features: [
        "AWS Integration",
        "Azure Services",
        "Cloud Migration"
      ]
    }
  ];

  const techStack = [
    { category: "Frontend", techs: ["React", "Angular", "Vue.js", "Next.js"] },
    { category: "Backend", techs: ["Node.js", "Python", "Java", ".NET"] },
    { category: "Database", techs: ["MongoDB", "PostgreSQL", "MySQL", "Redis"] },
    { category: "Cloud", techs: ["AWS", "Azure", "Google Cloud", "Docker"] }
  ];

  const developmentProcess = [
    {
      phase: "1. Discovery",
      description: "Requirements gathering and project planning"
    },
    {
      phase: "2. Design",
      description: "Architecture and UI/UX design"
    },
    {
      phase: "3. Development",
      description: "Agile development with regular updates"
    },
    {
      phase: "4. Testing",
      description: "Quality assurance and user testing"
    },
    {
      phase: "5. Deployment",
      description: "Launch and maintenance support"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Software Development Services</h1>
          <p className="text-xl text-gray-600">Building innovative solutions for modern businesses</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
              {service.icon}
              <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600 mb-6">{service.description}</p>
              <ul className="space-y-3">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-xl p-12 shadow-lg mb-16">
          <h2 className="text-3xl font-bold text-center mb-12">Our Technology Stack</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {techStack.map((stack, index) => (
              <div key={index}>
                <h3 className="text-xl font-bold mb-4 text-blue-600">{stack.category}</h3>
                <ul className="space-y-2">
                  {stack.techs.map((tech, idx) => (
                    <li key={idx} className="text-gray-600">{tech}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-xl p-12 shadow-lg mb-16">
          <h2 className="text-3xl font-bold text-center mb-12">Development Process</h2>
          <div className="space-y-8">
            {developmentProcess.map((step, index) => (
              <div key={index} className="flex items-start">
                <div className="font-bold text-blue-600 mr-4">{step.phase}</div>
                <div className="text-gray-600">{step.description}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-xl p-12 text-center">
          <h2 className="text-3xl font-bold mb-4">Start Your Project Today</h2>
          <p className="mb-8 text-lg">Let's discuss your software development needs</p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Schedule Consultation
          </button>
        </div>
      </div>
    </section>
  );
};

export default SoftwareDevelopmentService;