import React, { FormEvent } from 'react';
import { Server, Mail, Phone, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const sections = [
    {
      title: 'Services',
      links: [
        { label: 'Cloud Solutions', url: '/solutions/cloud-services' },
        { label: 'IT Support', url: '/services/support' },
        { label: 'Cybersecurity', url: '/solutions/cybersecurity' },
        { label: 'Software Development', url: '/services/development' },
        { label: 'Hardware Solutions', url: '/solutions/it-infrastructure' }
      ]
    },
    {
      title: 'Company',
      links: [
        { label: 'About Us', url: '/about' },
        { label: 'Become A Technician', url: '/become-technician' },
        { label: 'Contact', url: '/contact' }
      ]
    },
    {
      title: 'Support',
      links: [
        { label: 'Help Center', url: '/support-centre' },
        { label: 'Status', url: '/status' },
        { label: 'FAQs', url: '/support-centre#faqs' }
      ]
    }
  ] as const;

  const contacts = [
    { icon: Phone, text: '+1 (555) 123-4567', href: 'tel:+15551234567' },
    { icon: Mail, text: 'contact@2mtechelp.com', href: 'mailto:contact@2mtechelp.com' },
    { icon: MapPin, text: 'Adelaide, South Australia', href: 'https://maps.google.com/?q=Adelaide,South+Australia' }
  ] as const;

  const handleSubscribe = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add newsletter subscription logic here
    const form = e.currentTarget;
    const emailInput = form.querySelector('input[type="email"]') as HTMLInputElement;
    console.log('Subscribing email:', emailInput.value);
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-16">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-8 mb-8">
          {/* Company Info */}
          <div className="lg:col-span-4">
            <Link to="/" className="flex items-center mb-6 hover:opacity-90 transition-opacity">
              <Server className="h-8 w-8 text-blue-400 mr-2" />
              <span className="text-2xl font-bold">2Mtechelp</span>
            </Link>
            <p className="text-gray-400 mb-6">
              Leading provider of enterprise IT solutions and services in Adelaide and surrounding regions.
            </p>
            <div className="space-y-4">
              {contacts.map((contact, index) => (
                <a 
                  key={index} 
                  href={contact.href}
                  className="flex items-center text-gray-400 hover:text-white transition-colors"
                  target={contact.icon === MapPin ? "_blank" : undefined}
                  rel={contact.icon === MapPin ? "noopener noreferrer" : undefined}
                >
                  <contact.icon className="h-5 w-5 mr-3" />
                  <span>{contact.text}</span>
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          {sections.map((section, index) => (
            <div key={index} className="lg:col-span-2">
              <h3 className="text-lg font-bold mb-4">{section.title}</h3>
              <ul className="space-y-3">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link 
                      to={link.url} 
                      className="text-gray-400 hover:text-white transition-colors block"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Newsletter */}
          <div className="lg:col-span-4">
            <h3 className="text-lg font-bold mb-4">Stay Updated</h3>
            <p className="text-gray-400 mb-4">
              Subscribe to our newsletter for the latest updates and insights.
            </p>
            <form onSubmit={handleSubscribe} className="space-y-4">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button 
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8 mt-8">
          <div className="flex flex-col md:flex-row md:justify-between items-center">
            <p className="text-gray-400 text-sm mb-4 md:mb-0">
              © {new Date().getFullYear()} 2Mtechelp. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <Link to="/privacy-policy" className="text-gray-400 hover:text-white transition-colors text-sm">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-gray-400 hover:text-white transition-colors text-sm">
                Terms of Service
              </Link>
              <Link to="/cookie-policy" className="text-gray-400 hover:text-white transition-colors text-sm">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;