// src/components/ScrollToTop.tsx
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    setIsScrolling(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    // Wait for scroll to complete
    const timer = setTimeout(() => {
      setIsScrolling(false);
    }, 500); // Adjust time based on your needs

    return () => clearTimeout(timer);
  }, [pathname]);

  if (isScrolling) {
    return null; // or return a loading spinner
  }

  return null;
};