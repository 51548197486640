
import React from 'react';
import { Star, Quote, User, Building } from 'lucide-react';

interface Testimonial {
  name: string;
  role: string;
  quote: string;
  rating: number;
  company: string;
  bgColor: string;
  initials: string;
}

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "CTO, TechFin Solutions",
      quote: "2Mtechelp transformed our IT infrastructure, reducing costs by 40% while improving system performance.",
      rating: 5,
      company: "TechFin",
      bgColor: "bg-blue-100",
      initials: "SJ"
    },
    {
      name: "Michael Chen",
      role: "CEO, HealthCare Plus",
      quote: "Their cybersecurity solutions give us peace of mind. Best IT partner we've worked with.",
      rating: 5,
      company: "HealthCare",
      bgColor: "bg-green-100",
      initials: "MC"
    },
    {
      name: "David Wilson",
      role: "Operations Director, RetailCo",
      quote: "Exceptional service. They're not just vendors, they're strategic partners in our growth.",
      rating: 5,
      company: "RetailCo",
      bgColor: "bg-purple-100",
      initials: "DW"
    }
  ];

  const industries = [
    'Enterprise',
    'Healthcare',
    'Finance',
    'Retail',
    'Education',
    'Technology'
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <div className="inline-flex items-center bg-blue-100 rounded-full px-4 py-2 mb-4">
            <Quote className="w-4 h-4 text-blue-600 mr-2" />
            <span className="text-blue-600 font-medium">Client Success Stories</span>
          </div>
          <h2 className="text-4xl font-bold mb-6">
            Trusted by Industry Leaders
          </h2>
          <p className="text-xl text-gray-600">
            See why top companies choose us for their IT needs
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-xl p-8 hover:shadow-lg transition-all duration-300 border border-gray-100">
              <div className="flex items-center mb-6">
                <div className={`w-12 h-12 rounded-full ${testimonial.bgColor} flex items-center justify-center mr-4 text-blue-600 font-semibold`}>
                  {testimonial.initials}
                </div>
                <div>
                  <h3 className="font-bold">{testimonial.name}</h3>
                  <p className="text-gray-600 text-sm">{testimonial.role}</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                ))}
              </div>
              <p className="text-gray-700 mb-4">"{testimonial.quote}"</p>
              <div className="flex items-center text-gray-500 mt-6">
                <Building className="w-4 h-4 mr-2" />
                <span className="text-sm font-medium">{testimonial.company}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 mb-16">
          {industries.map((industry, index) => (
            <div key={index} className="flex flex-col items-center justify-center p-4 bg-white rounded-lg border border-gray-100 hover:shadow-md transition-all duration-300">
              <Building className="w-6 h-6 text-gray-400 mb-2" />
              <span className="text-sm text-gray-600 font-medium">{industry}</span>
            </div>
          ))}
        </div>

        <div className="text-center bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-12 text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-8">Join the leading companies that trust us with their IT needs</p>
          <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl">
            Schedule a Consultation
          </button>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
