import React from 'react';
import { Shield, Zap, Clock, Users, BarChart, Cloud, Server, Lock, LucideIcon } from 'lucide-react';

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description }) => (
  <div className="bg-white rounded-xl p-6 hover:shadow-xl transition-all duration-300 group">
    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:bg-blue-600 transition-colors">
      <Icon className="h-6 w-6 text-blue-600 group-hover:text-white transition-colors" />
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

interface Feature {
  icon: LucideIcon;
  title: string;
  description: string;
}

const FeaturesBenefits: React.FC = () => {
  const features: Feature[] = [
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Bank-grade security with 24/7 monitoring and threat detection"
    },
    {
      icon: Zap,
      title: "High Performance",
      description: "Lightning-fast systems with 99.9% uptime guarantee"
    },
    {
      icon: Cloud,
      title: "Cloud Solutions",
      description: "Scalable cloud infrastructure that grows with your business"
    },
    {
      icon: Users,
      title: "Dedicated Support",
      description: "24/7 expert support team always ready to help"
    },
    {
      icon: BarChart,
      title: "Analytics",
      description: "Real-time insights and performance monitoring"
    },
    {
      icon: Lock,
      title: "Data Protection",
      description: "Advanced encryption and backup solutions"
    }
  ];

  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <div className="inline-flex items-center bg-blue-100 rounded-full px-4 py-2 mb-4">
            <Server className="w-4 h-4 text-blue-600 mr-2" />
            <span className="text-blue-600 font-medium">Core Features</span>
          </div>
          <h2 className="text-4xl font-bold mb-6">
            Everything You Need to Succeed
          </h2>
          <p className="text-xl text-gray-600">
            Comprehensive IT solutions designed for modern businesses
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        <div className="mt-16 bg-blue-50 rounded-2xl p-12 text-center">
          <h3 className="text-2xl font-bold mb-4">Ready to get started?</h3>
          <p className="text-gray-600 mb-8">Join thousands of businesses that trust us</p>
          <button className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
            Schedule a Demo
          </button>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBenefits;