import React from 'react';
import { Monitor, Server, Wifi, Printer, Settings } from 'lucide-react';

interface HardwareService {
  icon: any;
  title: string;
  description: string;
  features: string[];
}

const HardwareSolutions: React.FC = () => {
  const services: HardwareService[] = [
    {
      icon: Monitor,
      title: "PCs & Workstations",
      description: "Custom-built computers and workstations for your needs",
      features: [
        "Desktop Computers",
        "Laptops",
        "Workstations",
        "Servers"
      ]
    },
    {
      icon: Wifi,
      title: "Networking Solutions",
      description: "Complete networking setup and maintenance",
      features: [
        "Wi-Fi Networks",
        "Network Security",
        "Cabling Solutions",
        "Network Optimization"
      ]
    },
    {
      icon: Printer,
      title: "Peripherals",
      description: "Professional-grade peripherals and accessories",
      features: [
        "Printers & Scanners",
        "Storage Solutions",
        "Backup Systems",
        "Security Hardware"
      ]
    },
    {
      icon: Settings,
      title: "Installation Services",
      description: "Expert installation and configuration",
      features: [
        "Onsite Setup",
        "Hardware Configuration",
        "Performance Tuning",
        "Technical Support"
      ]
    }
  ];

  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold mb-6">Hardware Solutions</h1>
          <p className="text-xl text-gray-600">
            Professional hardware solutions and installation services for homes and businesses
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                <service.icon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600 mb-6">{service.description}</p>
              <ul className="space-y-3">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-gray-700">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 bg-blue-600 text-white rounded-xl p-8 text-center">
          <h2 className="text-2xl font-bold mb-4">Need Hardware Solutions?</h2>
          <p className="mb-6">Let us help you choose and install the right hardware for your needs</p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50">
            Get a Quote
          </button>
        </div>
      </div>
    </section>
  );
};

export default HardwareSolutions;