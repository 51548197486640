import React from 'react';
import { Shield, Clock, Monitor, PhoneCall, Bell, Check } from 'lucide-react';

interface SupportService {
 icon: any;
 title: string;
 description: string;
 features: string[];
}

const ITSupport: React.FC = () => {
 const supportServices: SupportService[] = [
   {
     icon: PhoneCall,
     title: "24/7 Support",
     description: "Round-the-clock emergency IT assistance",
     features: [
       "Immediate Response",
       "Remote Support",
       "Onsite Assistance",
       "Holiday Coverage"
     ]
   },
   {
     icon: Monitor,
     title: "Remote Support",
     description: "Quick resolution for technical issues",
     features: [
       "Remote Diagnostics",
       "Software Support",
       "System Updates",
       "Quick Resolution"
     ]
   },
   {
     icon: Bell,
     title: "Proactive Maintenance",
     description: "Prevent issues before they occur",
     features: [
       "System Monitoring",
       "Regular Updates",
       "Health Checks",
       "Performance Optimization"
     ]
   },
   {
     icon: Shield,
     title: "Security Services",
     description: "Protect your systems and data",
     features: [
       "Virus Removal",
       "Malware Protection",
       "Security Updates",
       "Data Backup"
     ]
   }
 ];

 return (
   <section className="py-24 bg-white">
     <div className="container mx-auto px-4">
       <div className="max-w-3xl mx-auto text-center mb-16">
         <span className="bg-blue-100 text-blue-600 px-4 py-2 rounded-full text-sm font-medium">
           IT Support
         </span>
         <h1 className="text-4xl font-bold mt-6 mb-6">
           Comprehensive IT Support & Maintenance
         </h1>
         <p className="text-xl text-gray-600">
           Keep your systems running smoothly with our expert IT support
         </p>
       </div>

       <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
         {supportServices.map((service, index) => (
           <div key={index} className="bg-gray-50 rounded-xl p-8 hover:shadow-lg transition-all">
             <div className="flex items-center mb-6">
               <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                 <service.icon className="h-6 w-6 text-blue-600" />
               </div>
               <h3 className="text-xl font-bold ml-4">{service.title}</h3>
             </div>
             <p className="text-gray-600 mb-6">{service.description}</p>
             <ul className="space-y-3">
               {service.features.map((feature, idx) => (
                 <li key={idx} className="flex items-center text-gray-700">
                   <Check className="h-5 w-5 text-green-500 mr-2" />
                   {feature}
                 </li>
               ))}
             </ul>
           </div>
         ))}
       </div>

       <div className="bg-blue-600 rounded-xl p-12 text-center text-white">
         <h2 className="text-3xl font-bold mb-4">Ready for Reliable IT Support?</h2>
         <p className="text-xl mb-8">Get started with our professional IT support services</p>
         <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50">
           Contact Support
         </button>
       </div>
     </div>
   </section>
 );
};

export default ITSupport;