// ComingSoonPopup.tsx
import React, { useState, useEffect } from 'react';
import { X, Rocket, Timer } from 'lucide-react';

const ComingSoonPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [days, setDays] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setDays(prev => Math.max(0, prev - 1));
    }, 86400000); // Update every 24 hours

    return () => clearInterval(timer);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[70] flex items-center justify-center px-4">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm" 
        onClick={() => setIsOpen(false)}
      />
      <div className="relative bg-gradient-to-br from-blue-600 to-blue-700 text-white rounded-2xl p-8 md:p-12 max-w-lg w-full animate-fadeIn shadow-2xl">
        <button 
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-6">
          <div className="flex justify-center">
            <Rocket className="w-16 h-16 animate-bounce" />
          </div>
          
          <h2 className="text-3xl font-bold">
            We're Building Something Awesome!
          </h2>
          
          <p className="text-lg opacity-90">
            Our team is working hard to bring you the best IT support experience.
            Stay tuned for something amazing!
          </p>

          <div className="bg-white/10 rounded-lg p-6 backdrop-blur-sm">
            <div className="flex items-center justify-center gap-2 text-2xl font-bold">
              <Timer className="w-6 h-6" />
              <span>Launching in {days} days</span>
            </div>
          </div>

          <div className="pt-4">
            <button
              onClick={() => setIsOpen(false)}
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPopup;