import React from 'react';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';

interface ContactInfo {
 icon: any;
 title: string;
 value: string;
 description: string;
}

const ContactSection: React.FC = () => {
 const contactInfo: ContactInfo[] = [
   {
     icon: Phone,
     title: "Phone",
     value: "+1 (555) 123-4567",
     description: "24/7 Support Available"
   },
   {
     icon: Mail,
     title: "Email",
     value: "contact@2mtechelp.com",
     description: "Quick Response Time"
   },
   {
     icon: MapPin,
     title: "Location",
     value: "Adelaide, South Australia",
     description: "Serving All Major Areas"
   },
   {
     icon: Clock,
     title: "Hours",
     value: "24/7",
     description: "Always Available"
   }
 ];

 return (
   <section className="py-24 bg-white">
     <div className="container mx-auto px-4">
       <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
         <div>
           <h2 className="text-4xl font-bold mb-6">Let's Build Something Great Together</h2>
           <p className="text-xl text-gray-600 mb-8">
             Ready to transform your IT infrastructure? Contact us today for a free consultation.
           </p>

           <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
             {contactInfo.map((info, index) => (
               <div key={index} className="bg-white rounded-xl shadow p-6">
                 <info.icon className="h-6 w-6 text-blue-600 mb-4" />
                 <h3 className="font-bold mb-1">{info.title}</h3>
                 <p className="text-gray-900 font-medium mb-1">{info.value}</p>
                 <p className="text-sm text-gray-600">{info.description}</p>
               </div>
             ))}
           </div>
         </div>

         <div className="bg-white rounded-xl shadow p-8">
           <h3 className="text-2xl font-bold mb-6">Get in Touch</h3>
           <form className="space-y-6">
             <div>
               <label className="block text-sm font-medium mb-2">Full Name</label>
               <input
                 type="text"
                 className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                 placeholder="John Doe"
               />
             </div>
             <div>
               <label className="block text-sm font-medium mb-2">Email</label>
               <input
                 type="email"
                 className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                 placeholder="john@company.com"
               />
             </div>
             <div>
               <label className="block text-sm font-medium mb-2">Phone</label>
               <input
                 type="tel"
                 className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                 placeholder="+1 (555) 000-0000"
               />
             </div>
             <div>
               <label className="block text-sm font-medium mb-2">Message</label>
               <textarea
                 className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                 rows={4}
                 placeholder="Tell us about your project"
               ></textarea>
             </div>
             <button className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
               Send Message
             </button>
           </form>
         </div>
       </div>
     </div>
   </section>
 );
};

export default ContactSection;