import React, { useState } from 'react';
import { Search, MessageSquare, FileText, Phone, ChevronDown, ChevronRight, AlertCircle } from 'lucide-react';

const SupportCenter: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const categories = [
    {
      title: "Network Issues",
      articles: [
        "Troubleshooting Wi-Fi Connection",
        "Router Configuration Guide",
        "Network Security Best Practices",
        "Internet Speed Optimization"
      ]
    },
    {
      title: "Smart Home",
      articles: [
        "Device Setup Guide",
        "Voice Assistant Configuration",
        "Home Automation Tips",
        "Smart Security Systems"
      ]
    },
    {
      title: "IT Support",
      articles: [
        "Computer Maintenance Guide",
        "Data Backup Solutions",
        "Software Installation Help",
        "Virus Protection Guide"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-600 text-white py-16">
        <div className="max-w-4xl mx-auto px-6 text-center">
          <h1 className="text-4xl font-bold mb-6">How can we help you?</h1>
          <div className="relative max-w-2xl mx-auto">
            <Search className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
            <input 
              type="text"
              placeholder="Search for help articles..."
              className="w-full pl-12 pr-4 py-3 rounded-lg text-gray-900"
            />
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Help Categories */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              {categories.map((category, index) => (
                <div key={index} className="border-b last:border-b-0">
                  <button
                    onClick={() => setActiveCategory(activeCategory === category.title ? null : category.title)}
                    className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
                  >
                    <span className="font-semibold">{category.title}</span>
                    {activeCategory === category.title ? (
                      <ChevronDown className="h-5 w-5 text-gray-400" />
                    ) : (
                      <ChevronRight className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                  {activeCategory === category.title && (
                    <div className="px-4 pb-4">
                      <div className="space-y-2">
                        {category.articles.map((article, idx) => (
                          <a
                            key={idx}
                            href="#"
                            className="flex items-center text-gray-600 hover:text-blue-600 py-2"
                          >
                            <FileText className="h-4 w-4 mr-2" />
                            {article}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Quick Contact */}
          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Contact Support</h2>
              <div className="space-y-4">
                <button className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50">
                  <div className="flex items-center">
                    <MessageSquare className="h-5 w-5 text-blue-600 mr-3" />
                    <div>
                      <div className="font-medium">Live Chat</div>
                      <div className="text-sm text-gray-600">Available Now</div>
                    </div>
                  </div>
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                </button>

                <button className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50">
                  <div className="flex items-center">
                    <Phone className="h-5 w-5 text-blue-600 mr-3" />
                    <div>
                      <div className="font-medium">Call Support</div>
                      <div className="text-sm text-gray-600">24/7 Available</div>
                    </div>
                  </div>
                  <ChevronRight className="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex space-x-3">
                <AlertCircle className="h-5 w-5 text-blue-600" />
                <div>
                  <h3 className="font-medium">Emergency Support</h3>
                  <p className="text-gray-600 text-sm mt-1">
                    For urgent assistance, call our emergency line:
                    <br />
                    <span className="font-medium text-gray-900">1800 123 4567</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportCenter;