// NavBar.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, ChevronDown, Server } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

interface MenuItem {
  label: string;
  path?: string;
  children?: {
    label: string;
    description: string;
    path: string;
  }[];
}

const NavBar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setActiveDropdown(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMenuEnter = (index: number) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setActiveDropdown(index);
  };

  const handleMenuLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 300);
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Solutions',
      children: [
        { 
          label: 'Cloud Services', 
          description: 'Scalable cloud infrastructure solutions',
          path: '/solutions/cloud-services'
        },
        { 
          label: 'Cybersecurity', 
          description: 'Enterprise-grade security services',
          path: '/solutions/cybersecurity'
        },
        { 
          label: 'IT Infrastructure', 
          description: 'Managed IT services and support',
          path: '/solutions/it-infrastructure'
        }
      ]
    },
    {
      label: 'Services',
      children: [
        { 
          label: '24/7 Support', 
          description: 'Round-the-clock technical assistance',
          path: '/services/support'
        },
        { 
          label: 'Consulting', 
          description: 'Expert IT strategy and planning',
          path: '/services/consulting'
        },
        { 
          label: 'Development', 
          description: 'Custom software solutions',
          path: '/services/development'
        }
      ]
    },
    { 
      label: 'About Us',
      path: '/about'
    },
    { 
      label: 'Contact',
      path: '/contact'
    }
  ];

  return (
    <nav 
      className={`sticky top-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white/95 backdrop-blur-lg shadow-lg' : 'bg-white'
      }`}
    >
      <div className="container mx-auto px-4 py-2">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link 
  to="/" 
  className="flex items-center hover:opacity-90 transition-opacity"
>
  <div className="w-10 h-10 bg-gradient-to-r from-blue-600 to-blue-700 rounded-xl flex items-center justify-center">
    <img 
      src="/logo.svg" 
      alt="2Mtechelp Logo" 
      className="w-8 h-8 object-contain"
      onError={(e) => {
        e.currentTarget.onerror = null; // Prevent infinite loop
        e.currentTarget.style.display = 'none';
        // Show Server icon as fallback
        const serverIcon = document.createElement('div');
        serverIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white"><path d="M4 6h16v4H4zm0 8h16v4H4z"/></svg>';
        e.currentTarget.parentNode?.appendChild(serverIcon);
      }}
    />
  </div>
  <span className="ml-3 text-2xl font-bold bg-gradient-to-r from-blue-600 to-blue-700 bg-clip-text text-transparent">
    2Mtechelp
  </span>
</Link>

          {/* Desktop Menu */}
          <div ref={menuRef} className="hidden lg:flex items-center space-x-8">
            {menuItems.map((item, index) => (
              <div 
                key={index} 
                className="relative group"
                onMouseEnter={() => handleMenuEnter(index)}
                onMouseLeave={handleMenuLeave}
              >
                {item.children ? (
                  <button
                    className="flex items-center space-x-1 text-gray-600 hover:text-blue-600 transition-colors py-2"
                    aria-expanded={activeDropdown === index}
                  >
                    <span className="font-semibold text-gray-800">{item.label}</span>
                    <ChevronDown 
                      className={`h-4 w-4 transition-transform duration-300 ${
                        activeDropdown === index ? 'rotate-180' : ''
                      }`} 
                    />
                  </button>
                ) : (
                  <Link
                    to={item.path || '/'}
                    className={`font-semibold text-gray-800 hover:text-blue-600 transition-colors ${
                      location.pathname === item.path ? 'text-blue-600 font-medium' : ''
                    }`}
                  >
                    {item.label}
                  </Link>
                )}

                {item.children && activeDropdown === index && (
                  <div className="absolute top-full left-0 w-72 mt-2 bg-white rounded-xl shadow-xl py-3 border border-gray-100">
                    {item.children.map((child, childIndex) => (
                      <Link
                        key={childIndex}
                        to={child.path}
                        className="block px-5 py-3 hover:bg-gray-50 transition-colors"
                        onClick={() => setActiveDropdown(null)}
                      >
                        <div className="font-semibold text-gray-800">{child.label}</div>
                        <div className="text-sm text-gray-500 mt-0.5">{child.description}</div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}

            <Link 
              to="/booking-slot"
              className="px-6 py-2.5 rounded-lg font-semibold bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:shadow-lg hover:shadow-blue-500/20 transition-all duration-300"
            >
              Book Service
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="lg:hidden p-2 hover:bg-gray-100 rounded-lg transition-colors"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-expanded={isMobileMenuOpen}
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6 text-gray-600" />
            ) : (
              <Menu className="h-6 w-6 text-gray-600" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden bg-white border-t border-gray-100 rounded-b-xl py-4">
            {menuItems.map((item, index) => (
              <div key={index} className="px-4">
                {item.children ? (
                  <>
                    <button 
                      className="flex items-center justify-between w-full py-3 text-gray-600 hover:text-blue-600 transition-colors"
                      onClick={() => setActiveDropdown(activeDropdown === index ? null : index)}
                      aria-expanded={activeDropdown === index}
                    >
                      {item.label}
                      <ChevronDown 
                        className={`h-4 w-4 transition-transform duration-200 ${
                          activeDropdown === index ? 'rotate-180' : ''
                        }`} 
                      />
                    </button>

                    {activeDropdown === index && (
                      <div className="ml-4 space-y-2 mb-2">
                        {item.children.map((child, childIndex) => (
                          <Link
                            key={childIndex}
                            to={child.path}
                            className="block py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors"
                            onClick={() => {
                              setIsMobileMenuOpen(false);
                              setActiveDropdown(null);
                            }}
                          >
                            <div className="font-medium text-gray-800">{child.label}</div>
                            <div className="text-sm text-gray-500">{child.description}</div>
                          </Link>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    to={item.path || '/'}
                    className="block py-3 text-gray-600 hover:text-blue-600 transition-colors"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            ))}
            <div className="px-4 mt-4">
              <Link 
                to="/booking-slot"
                className="block w-full px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-lg font-semibold hover:shadow-lg hover:shadow-blue-500/20 transition-all duration-300 text-center"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Book Service
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;