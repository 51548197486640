// CloudSolutions.tsx
import React from 'react';
import { Cloud, Server, Lock, Database, ArrowRight, CheckCircle } from 'lucide-react';

const CloudSolutions = () => {
  const cloudServices = [
    {
      icon: <Cloud className="w-12 h-12 text-blue-600" />,
      title: "Cloud Infrastructure",
      description: "Scalable and secure cloud infrastructure solutions",
      features: ["AWS/Azure/GCP", "Auto-scaling", "Load Balancing"]
    },
    {
      icon: <Server className="w-12 h-12 text-blue-600" />,
      title: "Migration Services",
      description: "Seamless transition to cloud platforms",
      features: ["Data Migration", "Zero Downtime", "Risk Management"]
    },
    {
      icon: <Lock className="w-12 h-12 text-blue-600" />,
      title: "Cloud Security",
      description: "Enterprise-grade security solutions",
      features: ["Encryption", "Compliance", "Access Control"]
    }
  ];

  const migrationSteps = [
    { phase: "Assessment", description: "Evaluate current infrastructure" },
    { phase: "Planning", description: "Design migration strategy" },
    { phase: "Migration", description: "Execute cloud transition" },
    { phase: "Optimization", description: "Enhance performance" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-blue-600 text-white">
        <div className="absolute inset-0 opacity-10">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `radial-gradient(circle at 1px 1px, white 1px, transparent 0)`,
              backgroundSize: '40px 40px'
            }}
          ></div>
        </div>
        <div className="container mx-auto px-4 py-24 relative">
          {/* Rest of hero section content remains same */}
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {cloudServices.map((service, index) => (
              <div key={index} className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
                <div className="mb-6">{service.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-700">
                      <CheckCircle className="w-5 h-5 text-blue-600 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Migration Process */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Cloud Migration Process</h2>
          <div className="relative">
            <div className="absolute top-1/2 left-0 w-full h-0.5 bg-blue-200"></div>
            <div className="grid md:grid-cols-4 gap-8 relative">
              {migrationSteps.map((step, index) => (
                <div key={index} className="bg-white rounded-xl p-8 shadow-lg relative">
                  <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4">
                    {index + 1}
                  </div>
                  <h3 className="text-xl font-bold mb-2">{step.phase}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Cloud Partners 
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Our Cloud Partners</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {['AWS', 'Azure', 'Google Cloud', 'Oracle Cloud'].map((partner, index) => (
              <div key={index} className="flex items-center justify-center p-8 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow">
                <span className="text-xl font-semibold text-gray-700">{partner}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      */}

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-blue-700 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Move to the Cloud?</h2>
          <p className="text-xl opacity-90 mb-8 max-w-2xl mx-auto">
            Let our experts guide you through a seamless cloud transformation journey
          </p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Schedule Consultation
          </button>
        </div>
      </section>
    </div>
  );
};

export default CloudSolutions;