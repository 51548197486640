export const metaData = {
    home: {
      title: "Professional IT Services & Support in Adelaide",
      description: "Expert IT solutions, network setup, and tech support across Adelaide & Hills. 24/7 service, competitive rates, trusted local technicians.",
      keywords: "IT services Adelaide, tech support, network setup, computer repair"
    },
    services: {
      title: "IT Services - Network, Smart Home & Tech Support",
      description: "Comprehensive IT services including network setup, smart home installation, and ongoing tech support in Adelaide region.",
      keywords: "IT support Adelaide, smart home setup, network installation"
    },
    support: {
      title: "24/7 IT Support & Help Center",
      description: "Round-the-clock IT support and technical assistance for homes and businesses in Adelaide.",
      keywords: "IT help Adelaide, tech support, computer help"
    },
    technician: {
      title: "IT Technician Portal - Service Management",
      description: "Technician portal for managing IT service requests and customer support in Adelaide.",
      keywords: "IT technician portal, service management, tech support"
    },
    customer: {
      title: "Customer Portal - IT Service Management",
      description: "Manage your IT services, track support requests, and access technical assistance.",
      keywords: "IT customer portal, service tracking, tech support portal"
    }
  };