// ConsultingServices.tsx
import React from 'react';
import { FaLaptopCode, FaNetworkWired, FaShieldAlt, FaClock } from 'react-icons/fa';

const ConsultingServices = () => {
  const services = [
    {
      icon: <FaLaptopCode className="w-12 h-12 text-blue-600 mb-4" />,
      title: "On-Site Tech Support",
      description: "Expert IT support at your doorstep for quick resolution of hardware and software issues",
      features: [
        "Same-day emergency response",
        "Hardware diagnostics & repair",
        "Software troubleshooting"
      ]
    },
    {
      icon: <FaNetworkWired className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Network Solutions",
      description: "Professional network setup and maintenance services at your location",
      features: [
        "Network installation",
        "WiFi optimization",
        "Cable management"
      ]
    },
    {
      icon: <FaShieldAlt className="w-12 h-12 text-blue-600 mb-4" />,
      title: "Security Consulting",
      description: "On-premise security assessment and implementation",
      features: [
        "Security audit",
        "Antivirus setup",
        "Data backup solutions"
      ]
    }
  ];

  const processSteps = [
    {
      number: "01",
      title: "Book Appointment",
      description: "Schedule a convenient time for our visit"
    },
    {
      number: "02",
      title: "Diagnosis",
      description: "Our experts assess your IT needs on-site"
    },
    {
      number: "03",
      title: "Solution",
      description: "Same-day resolution for most issues"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Doorstep IT Consulting</h1>
          <p className="text-xl text-gray-600">Expert tech support when and where you need it</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
              {service.icon}
              <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600 mb-6">{service.description}</p>
              <ul className="space-y-3">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-xl p-12 shadow-lg mb-16">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {processSteps.map((step, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-blue-600 mb-4">{step.number}</div>
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-xl p-12 text-center">
          <div className="flex items-center justify-center mb-6">
            <FaClock className="w-12 h-12" />
          </div>
          <h2 className="text-3xl font-bold mb-4">24/7 Emergency Support Available</h2>
          <p className="mb-8 text-lg">Quick response team ready to help with urgent IT issues</p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Call Emergency Support
          </button>
        </div>
      </div>
    </section>
  );
};

export default ConsultingServices;