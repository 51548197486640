// src/index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import NavBar from './components/Header/NavBar';
import BannerSection from './components/Banner/Banner';
import Footer from './components/Footer/footer';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="relative">
        <div className="fixed top-0 w-full z-[60]">
          <BannerSection />
        </div>
        <div className="sticky top-[88px] z-50">
          <NavBar />
        </div>
        <div >
          <App />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);