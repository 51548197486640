import React from 'react';
import { Settings, Bell, User } from 'lucide-react';

const CustomerInterface: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto p-6">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                <User className="w-8 h-8 text-blue-600" />
              </div>
              <div>
                <h1 className="text-2xl font-bold">Welcome back, John</h1>
                <p className="text-gray-600">Manage your services and appointments</p>
              </div>
            </div>
            <div className="flex space-x-4">
              <button className="p-2 rounded-lg hover:bg-gray-100">
                <Bell className="w-6 h-6 text-gray-600" />
              </button>
              <button className="p-2 rounded-lg hover:bg-gray-100">
                <Settings className="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Quick Actions */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Quick Actions</h2>
              <div className="space-y-3">
                <button className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                  Book New Service
                </button>
                <button className="w-full py-3 border text-gray-700 rounded-lg hover:bg-gray-50">
                  View Appointments
                </button>
                <button className="w-full py-3 border text-gray-700 rounded-lg hover:bg-gray-50">
                  Contact Support
                </button>
              </div>
            </div>
          </div>

          {/* Service Info */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Upcoming Services</h2>
              <div className="space-y-4">
                {[
                  { service: 'Network Setup', date: 'March 15', time: '10:00 AM' },
                  { service: 'IT Support', date: 'March 18', time: '2:30 PM' }
                ].map((item, index) => (
                  <div key={index} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div>
                      <h3 className="font-medium">{item.service}</h3>
                      <p className="text-sm text-gray-500">{item.date} at {item.time}</p>
                    </div>
                    <button className="text-blue-600 hover:underline">Details</button>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Recent Services</h2>
              <div className="space-y-4">
                {[
                  { service: 'Smart Home Setup', date: 'March 1', status: 'Completed' },
                  { service: 'Security Installation', date: 'February 25', status: 'Completed' }
                ].map((item, index) => (
                  <div key={index} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div>
                      <h3 className="font-medium">{item.service}</h3>
                      <p className="text-sm text-gray-500">{item.date}</p>
                    </div>
                    <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                      {item.status}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInterface;