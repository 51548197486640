// src/App.tsx
import React, { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import HomeITServices from './pages/HomeITServices';
import HardwareSolutions from './pages/HardwareSolution';
import ITSupport from './pages/ITSupport';
import BookingScreen from './pages/SlotBooking';
import TechnicianInterface from './pages/Technician';
import CustomerInterface from './pages/CustmerPortal';
import SupportCenter from './pages/SupportCentre';
import AuthScreens from './pages/AuthScreen';
import { HelmetProvider } from 'react-helmet-async';
import ContactSection from './components/Contact';
import AboutUs from './pages/AboutUs';
import ConsultingServices from './pages/ConsultingServices';
import SoftwareDevelopmentService from './pages/SoftwareDevelopmentService';
import CyberSecurityServices from './pages/CyberSecurityServices';
import CloudSolutions from './pages/CloudSolutions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
import BecomeTechnician from './pages/BecomeATechnician';
import { ScrollToTop } from './components/ScrollToTop';
import ComingSoonPopup from './components/ComingSoonPopup';


interface RouteConfig {
  path: string;
  element: React.ReactNode;
  title: string;
}

const App: React.FC = () => {
  const routes: RouteConfig[] = useMemo(() => [
    { path: '/', element: <HomePage />, title: 'Home' },
    { path: '/homes', element: <HomePage />, title: 'Home' },
    { path: '/contact', element: <ContactSection />, title: 'Contact Us' },
    { path: '/about', element: <AboutUs />, title: 'About Us' },
    {path: '/solutions/cybersecurity', element: <CyberSecurityServices />, title: 'Cyber Security IT Services'},
    {path: '/services/support', element: <ITSupport />, title: 'Home Hardware Support'},
   {path: '/services/consulting', element: <ConsultingServices />, title: 'Home IT Solutions'},
   {path: '/services/development', element: <SoftwareDevelopmentService />, title: 'Software Development'},
   {path: '/solutions/cloud-services', element: <CloudSolutions />, title: 'Home IT Services'},
    {path: '/solutions/it-infrastructure', element: <HardwareSolutions />, title: 'Home Hardware Solutions'},
    {path: '/booking-slot', element: <BookingScreen />, title: 'Home Serices Booking'},
    { path: '/technician-interface', element: <TechnicianInterface />, title: 'TechnicianInterface' },
    { path: '/customer-interface', element: <CustomerInterface />, title: 'CustomerInterface' },
    {path: '/support-centre', element: <SupportCenter />, title: 'SupportCenter'},
    {path: '/login', element: <AuthScreens.Login />, title: 'Login'},
    {path: '/register', element: <AuthScreens.Register />, title: 'Register'},
    {path: '/forgot-password', element: <AuthScreens.ForgotPassword />, title: 'Forgot Password'},
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
      title: 'Privacy Policy - 2Mtechelp'
    },
    {
      path: '/terms-of-service',
      element: <TermsOfService />,
      title: 'Terms of Service - 2Mtechelp'
    },
    {
      path: '/cookie-policy',
      element: <CookiePolicy />,
      title: 'Cookie Policy - 2Mtechelp'
    },
    {
      path: '/become-technician',
      element: <BecomeTechnician />,
      title: 'Become a Technician - 2MTechHelp'
    }
  ], []);

  React.useEffect(() => {
    const updateTitle = (): void => {
      const currentRoute = routes.find(
        route => route.path === window.location.pathname
      );
      document.title = `HomeServices | ${currentRoute?.title || 'Welcome'}`;
    };

    updateTitle();
    window.addEventListener('popstate', updateTitle);
    return () => window.removeEventListener('popstate', updateTitle);
  }, [routes]);

  return (
    <HelmetProvider>
      <ComingSoonPopup />
      <ScrollToTop />
        <Routes>
          
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </HelmetProvider>
      
  );
};

export default App;