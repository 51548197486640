// src/pages/HomePage.tsx
import React from 'react';
import NavBar from '../components/Header/NavBar';
import Footer from '../components/Footer/footer';
import HeroSection from '../components/hero';
import ServicesShowcase from '../components/ServicesShowcase';
import SolutionsShowcase from '../components/CloudSolutions';
import TestimonialsSection from '../components/Testimonials';
import FeaturesBenefits from '../components/FeaturesBenefits';
import ProcessSection from '../components/Process';
import ContactSection from '../components/Contact';
import Meta from '../components/SEO/Meta';
import { metaData } from '../config/metadata';

const HomePage: React.FC = () => {
  return (
    
    <div className="relative flex flex-col min-h-screen">
      <Meta {...metaData.home}/>
      {/* Top Banner - Now outside the fixed header */}
    
      
      {/* Fixed NavBar */}
      

      {/* Main Content */}
      <main>
        <HeroSection />
        <ServicesShowcase />
        <SolutionsShowcase />
        <TestimonialsSection />
        <FeaturesBenefits />
        <ProcessSection />
        <ContactSection />
      </main>

    
    </div>
  );
};

export default HomePage;