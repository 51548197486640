// src/components/BookingScreen.tsx
import React, { useState } from 'react';
import { Calendar, Clock, MapPin, User, Phone, Mail, MessageSquare, X } from 'lucide-react';

interface Service {
 id: string;
 name: string;
 price: string;
}

const BookingScreen: React.FC = () => {
 const [selectedServices, setSelectedServices] = useState<Service[]>([]);
 const [selectedTime, setSelectedTime] = useState<string>('');

 const services: Service[] = [
   { id: '1', name: 'IT Support & Troubleshooting', price: '$120/hr' },
   { id: '2', name: 'Network Setup', price: '$150/hr' },
   { id: '3', name: 'Smart Home Installation', price: '$180/hr' },
   { id: '4', name: 'Security Services', price: '$160/hr' },
   { id: '5', name: 'Hardware Repair', price: '$100/hr' },
   { id: '6', name: 'Software Installation', price: '$90/hr' }
 ];

 const timeSlots = [
   { time: '09:00 AM', available: true },
   { time: '10:00 AM', available: true },
   { time: '11:00 AM', available: false },
   { time: '02:00 PM', available: true },
   { time: '03:00 PM', available: true },
   { time: '04:00 PM', available: true }
 ];

 const toggleService = (service: Service) => {
   if (selectedServices.find(s => s.id === service.id)) {
     setSelectedServices(selectedServices.filter(s => s.id !== service.id));
   } else {
     setSelectedServices([...selectedServices, service]);
   }
 };

 const removeService = (serviceId: string) => {
   setSelectedServices(selectedServices.filter(s => s.id !== serviceId));
 };

 const calculateTotal = () => {
   return selectedServices.reduce((total, service) => {
     return total + parseInt(service.price.replace(/\D/g, ''));
   }, 0);
 };

 return (
   <div className="min-h-screen bg-gray-50 py-12">
     <div className="container mx-auto px-4">
       <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg">
         <div className="bg-blue-600 text-white p-8 rounded-t-2xl">
           <h1 className="text-3xl font-bold mb-2">Book IT Services</h1>
           <p className="text-blue-100">Select multiple services for your appointment</p>
         </div>

         <div className="p-8">
           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
             {/* Left Column */}
             <div className="space-y-6">
               <div>
                 <h2 className="text-xl font-semibold mb-4">Available Services</h2>
                 <div className="space-y-2">
                   {services.map((service) => (
                     <button
                       key={service.id}
                       className={`w-full p-3 rounded-lg text-left flex justify-between items-center ${
                         selectedServices.find(s => s.id === service.id)
                           ? 'bg-blue-600 text-white'
                           : 'bg-gray-50 hover:bg-gray-100'
                       }`}
                       onClick={() => toggleService(service)}
                     >
                       <span>{service.name}</span>
                       <span className="text-sm">{service.price}</span>
                     </button>
                   ))}
                 </div>
               </div>

               {selectedServices.length > 0 && (
                 <div className="bg-gray-50 p-4 rounded-lg">
                   <h3 className="font-semibold mb-3">Selected Services</h3>
                   <div className="space-y-2">
                     {selectedServices.map((service) => (
                       <div key={service.id} className="flex justify-between items-center bg-white p-2 rounded">
                         <span>{service.name}</span>
                         <button 
                           onClick={() => removeService(service.id)}
                           className="text-gray-400 hover:text-red-500"
                         >
                           <X className="h-4 w-4" />
                         </button>
                       </div>
                     ))}
                     <div className="pt-2 border-t mt-3">
                       <div className="flex justify-between font-semibold">
                         <span>Estimated Total:</span>
                         <span>${calculateTotal()}</span>
                       </div>
                     </div>
                   </div>
                 </div>
               )}

               {/* Time Slots */}
               <div>
                 <h2 className="text-xl font-semibold mb-4">Available Time Slots</h2>
                 <div className="grid grid-cols-2 gap-2">
                   {timeSlots.map((slot, index) => (
                     <button
                       key={index}
                       disabled={!slot.available}
                       className={`p-3 rounded-lg text-center ${
                         selectedTime === slot.time
                           ? 'bg-blue-600 text-white'
                           : slot.available
                           ? 'bg-gray-50 hover:bg-gray-100'
                           : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                       }`}
                       onClick={() => setSelectedTime(slot.time)}
                     >
                       {slot.time}
                     </button>
                   ))}
                 </div>
               </div>
             </div>

             {/* Right Column - Contact Form */}
             <div>
               <h2 className="text-xl font-semibold mb-4">Your Details</h2>
               <form className="space-y-4">
                 <div>
                   <label className="block text-sm font-medium mb-1">Name</label>
                   <div className="relative">
                     <User className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                     <input type="text" className="w-full pl-10 pr-4 py-2 border rounded-lg" />
                   </div>
                 </div>

                 <div>
                   <label className="block text-sm font-medium mb-1">Phone</label>
                   <div className="relative">
                     <Phone className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                     <input type="tel" className="w-full pl-10 pr-4 py-2 border rounded-lg" />
                   </div>
                 </div>

                 <div>
                   <label className="block text-sm font-medium mb-1">Address</label>
                   <div className="relative">
                     <MapPin className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                     <input type="text" className="w-full pl-10 pr-4 py-2 border rounded-lg" />
                   </div>
                 </div>

                 <div>
                   <label className="block text-sm font-medium mb-1">Notes</label>
                   <div className="relative">
                     <MessageSquare className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                     <textarea className="w-full pl-10 pr-4 py-2 border rounded-lg" rows={3} />
                   </div>
                 </div>
               </form>
             </div>
           </div>

           <div className="mt-8 pt-6 border-t">
             <button
               disabled={selectedServices.length === 0 || !selectedTime}
               className={`w-full py-3 rounded-lg font-semibold ${
                 selectedServices.length === 0 || !selectedTime
                   ? 'bg-gray-300 cursor-not-allowed'
                   : 'bg-blue-600 text-white hover:bg-blue-700'
               }`}
             >
               Book Services
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default BookingScreen;